<!--
File: Report_11_TotalElevationZones.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />

      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_1') + $t('label.hectares')" md-sort-by="zone_1" md-numeric>
            {{ item.zone_1 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_2') + $t('label.hectares')" md-sort-by="zone_2" md-numeric>
            {{ item.zone_2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_3') + $t('label.hectares')" md-sort-by="zone_3" md-numeric>
            {{ item.zone_3 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_4') + $t('label.hectares')" md-sort-by="zone_4" md-numeric>
            {{ item.zone_4 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_5') + $t('label.hectares')" md-sort-by="zone_5" md-numeric>
            {{ item.zone_5 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_6') + $t('label.hectares')" md-sort-by="zone_6" md-numeric>
            {{ item.zone_6 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.total') + $t('label.hectares')" md-sort-by="total" md-numeric>
            {{ item.total | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      
      <md-table id="printFooter">
        <md-table-row>
          <md-table-head style="max-width: 200px;">{{ $t('label.total') }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_1 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_2 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_3 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_4 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_5 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_6 | numFormat }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.total | numFormat }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-total-elevation-zones',
  data() {
    return {
      btnDisabled: true,
      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc',
    }
  },
  components: {
  },

  mounted() {
    this.$store.commit('SET_REPORT_11', [])
    this.reloadData()
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      this.$store.dispatch('REPORT_11_TOTAL_ELEVATION_ZONES').then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },

    print() {
      this.showSpinner = true
      let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
      prtHtml += "<h4 style='text-align:center'>" + this.$t('route.report_total_elevation_zones') + '<br>'
      prtHtml += '</h4>'

      let tableHeaders = `<tr><th>${this.$t('road_network.region')}</th>
                  <th>${this.$t('label.zone_1') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.zone_2') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.zone_3') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.zone_4') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.zone_5') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.zone_6') + this.$t('label.hectares')}</th>
                  <th>${this.$t('label.total') + this.$t('label.hectares')}</th>
        </tr>`

      let tableRows = ''
      this.report.forEach(item => {
        tableRows += `<tr><td>${item.region_description}</td>
            <td class='numCell'>${numFormat(item.zone_1)}</td>
            <td class='numCell'>${numFormat(item.zone_2)}</td>
            <td class='numCell'>${numFormat(item.zone_3)}</td>
            <td class='numCell'>${numFormat(item.zone_4)}</td>
            <td class='numCell'>${numFormat(item.zone_5)}</td>
            <td class='numCell'>${numFormat(item.zone_6)}</td>
            <td class='numCell'>${numFormat(item.total)}</td>
          </tr>`
      })

      tableRows += `<tr><th>${this.$t('label.total')}</th>
          <td class='numCell'>${numFormat(this.totalZones.zone_1)}</td>
          <td class='numCell'>${numFormat(this.totalZones.zone_2)}</td>
          <td class='numCell'>${numFormat(this.totalZones.zone_3)}</td>
          <td class='numCell'>${numFormat(this.totalZones.zone_4)}</td>
          <td class='numCell'>${numFormat(this.totalZones.zone_5)}</td>
          <td class='numCell'>${numFormat(this.totalZones.zone_6)}</td>
          <td class='numCell'>${numFormat(this.totalZones.total)}</td>
      </tr>`

      this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
    },
  },

  computed: {
    report() {
      return this.customSort(this.$store.state.Reports.report_11_data)
    },
    totalZones() {
      const zones = ['zone_1', 'zone_2', 'zone_3', 'zone_4', 'zone_5', 'zone_6', 'total'];
      const totals = zones.reduce((acc, zone) => ({ ...acc, [zone]: 0 }), {});

      return this.report.reduce((accumulator, currentValue) => {
        zones.forEach(zone => {
          accumulator[zone] += currentValue[zone];
        });
        return accumulator;
      }, totals);
    },
  },
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>